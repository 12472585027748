*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  
}

.Header {
  background-color: #FFFFFF;
  height: auto;
  text-align: center;
  border-bottom: 1px solid #c2c2c2;
  box-shadow: 0px 1px #efefef;
  width: 90%;
  margin: auto;
}

.contentButton {
  background-color: #000000;
}


h1 {
  color: #E00000;
  font-size: 50px;
  font-weight: 900;
  text-shadow: 1px 1px #a90000;
  padding-left: 10px;
  padding-top: 11px;
  margin-bottom: 25px;
}

